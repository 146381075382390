<template>
    <div>
        <div class="intro-y box p-5 mt-5">
            <form
                method="post"
                class="grid grid-cols-12 gap-6"
                :class="{ 'disabled-form': loading }"
                @submit.prevent="save"
            >
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="relative col-span-12">
                    <label for="form-name" class="form-label">
                        Название
                        <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.title.$errors.length" for="form-name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-name"
                        v-model="form.title"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.title.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-12">
                    <label for="form-description" class="form-label">Описание</label>
                    <CustomWyswig id="form-description" v-model="form.description" class="form-control w-full" />
                </div>

                <div class="relative col-span-6">
                    <label class="form-label">
                        Изображение
                        <sup v-if="v$.form.image.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.image.$errors.length" class="form-image-text-error">
                        {{ getOverrideMessage(v$.form.image.$errors[0].$message) }}
                    </label>
                    <UploadImage
                        v-model="form.image"
                        v-model:delete="form.image_delete"
                        recommended-sizes="368x160px"
                        :has-error="!!v$.form.image.$errors.length"
                    ></UploadImage>
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'attractions' }" class="btn btn-secondary w-24 mr-2 mb-2"
                        >Отмена</router-link
                    >
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(true)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save()">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse } from '@/mixins/form/form-mixin';
import { attractionsMixin } from '@/mixins/form/attractions-mixin';
import Preloader from '@/components/preloader/Main';
import UploadImage from '@/components/upload-image/UploadImage';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';

export default {
    name: 'Edit',
    components: { UploadImage, Preloader, CustomWyswig },
    mixins: [errorResponse, attractionsMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                title: { required },
                image: { required },
            },
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Редактирование достопримечательности');
        await this.fetchAttraction();
    },
    methods: {
        prepareFormData(form) {
            const preparedFormData = JSON.parse(JSON.stringify(form));

            preparedFormData.image = typeof preparedFormData.image === 'string' ? null : form.image;

            return preparedFormData;
        },
        async save(redirect = false) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                const formData = formPreparation(this.prepareFormData(this.form));
                const { data } = await this.axios.post('/attractions/' + this.$route.params.id, formData, {
                    params: { _method: 'patch' },
                });

                this.$notify(data.message);

                if (redirect) {
                    await this.$router.push({ name: 'attractions' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            } finally {
                this.loading = false;
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped></style>
